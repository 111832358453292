import { instance } from '../provider';

// REVIEW Request.js 는 위의 것 하나만 test case 처럼 바꿈
// Request.js에는 params로 표시가 되어있는데 실제로 Postman에는 form-data로 명시되어 있으며, Timer.js에서도 FormData로 넘기고 있다. 앞으로 작업할 때에도 확실하게 params 인지 data 인지 파악하여 bracket안에 넣어 넘김으로써 처음 코드를 보아도 정확하게 구분할 수 있도록 한다.

/**
 * 클라우드 유저만 호출 해야 함.
 * @returns Boolean
 */
export const getUserKernelType = () => {
  return instance.get(`/api-users/status/get_use_cloud/`);
};

export const setUserKernelType = () => {
  return instance.post(`/api-users/status/change_use_cloud/`);
};

export const getUserContainerStatus = () => {
  // Response Type
  // {
  //   userInfo: {
  //     localschoolId: number;
  //     useWorkspaceInfo?: {
  //       aiffelNodeId: string;
  //       clusterName: string;
  //       containerNode: string;
  //       createdAt: number;
  //       endpoint: {
  //         jupyter: string;
  //         shell: string;
  //       };
  //       imagePath: string;
  //       isRunning: boolean;
  //       localschoolId: number;
  //       machineType: string;
  //       maxDurationMinute: number;
  //       namespaceId: string;
  //       remainingTimeMinute: number;
  //       sharedStorageId: string;
  //       statusCheckTaskId: string;
  //       userId: string;
  //       userStorageId: string;
  //       workerAddress: string;
  //       workspaceId: string;
  //     };
  //   };
  // }
  return instance.post(`/api-comcom/getUserStatus/`);
};

/**
 * @typedef {Object} EditContainerFormData
 * @property {String} browser
 * @property {String} visitor_id
 * @property {String} node_schedule
 *
 * @param {EditContainerFormData} formData
 * */
export const editContainer = (formData) => {
  return instance.post(`/api-comcom/editContainer/`, formData);
};

/**
 *
 * @param {String} browserName
 * @param {String} visitorId
 * @returns
 */
export const editContainerForExtend = (browserName, visitorId) => {
  let formData = new FormData();
  formData.append('browser', browserName);
  formData.append('visitor_id', visitorId);
  return instance.post(`/api-comcom/editContainer/`, formData);
};

/**
 *
 * @param {number} containerId
 * @param {string} email
 * @returns {Object}
 */
export const terminateContainer = (email) => {
  let formData = new FormData();
  formData.append('user_email', email);
  return instance.post(`api-comcom/terminateContainerPostman/`, formData);
};

export const terminateUserContainer = () => {
  return instance.post(`api-comcom/terminateContainer/`);
};

/**
 * @returns {Object}
 */
export const getLocalSchoolData = () => {
  return instance.get(`api-dashboard/localschool/`);
};

export const getAllUserStatus = (schoolId) => {
  let formData = new FormData();
  if (schoolId) {
    formData.append('localschool_id', schoolId);
  } else {
    formData = null;
  }
  return instance.post(`api-comcom/getAllUserStatus/`, formData);
};

export const getContainerQuota = (schoolId) => {
  let formData = new FormData();
  if (schoolId) {
    formData.append('localschool_id', schoolId);
  } else {
    formData = null;
  }
  return instance.post(`api-comcom/getContainerQuota/`, formData);
};

/**
 * 로컬스쿨의 사용자 목록과 상세 정보를 가져옵니다
 * @param {number} schoolId - 로컬스쿨 ID
 * @param {number} [inactiveDays] - 비활성 일수 (선택값, 미입력시 전체 조회)
 * @returns {Promise<{
 *   localschool: { id: number, name: string },
 *   cluster_info: Object,
 *   total_count: number,
 *   filtered_count: number,
 *   users: Array<{
 *     workspace_info: Object,
 *     user_info: {
 *       id: number,
 *       email: string,
 *       first_name: string,
 *       last_login: string | null,
 *       date_joined: string,
 *       days_since_last_login: number | null
 *     }
 *   }>
 * }>}
 */
export const getLocalSchoolUsers = (schoolId, inactiveDays) => {
  let formData = new FormData();
  formData.append('localschool_id', schoolId);
  if (inactiveDays !== undefined) {
    formData.append('inactive_days', inactiveDays);
  }
  return instance.post(`api-comcom/getLocalSchoolUsers/`, formData);
};
