import React, { useCallback, Fragment } from 'react';
import classNames from 'classnames';
import style from './DefaultModalComponent.module.scss';
import { ModalProps } from './modalManager';
import { Dialog, Transition } from '@headlessui/react';
import { Info, Warning, CheckCircle } from 'phosphor-react';

const returnIconBgColor = (type: ModalProps['type']) => {
  switch (type) {
    case 'warning':
    case 'confirm':
      return 'bg-y-100';
    case 'delete':
    case 'error':
      return 'bg-red-100';
    case 'success':
      return 'bg-green-400';
    case 'info':
      return 'bg-blue-400';
    default:
      return 'bg-gray-100';
  }
};

export default function DefaultModalComponent<T = any>({
  className,
  title,
  content,
  buttons,
  type,
  close,
}: ModalProps<T>) {
  const handleButtonClick = useCallback(
    (e, onClick) => {
      // eslint-disable-next-line no-unused-expressions
      onClick?.(e, close);
    },
    [close],
  );

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className={'relative z-30'} onClose={() => close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  {type !== 'default' && (
                    <div
                      className={classNames(
                        returnIconBgColor(type),
                        'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                      )}
                    >
                      {(type === 'warning' || type === 'confirm') && (
                        <Info size={24} className="h-6 w-6 text-y-600" />
                      )}
                      {(type === 'delete' || type === 'error') && (
                        <Warning size={24} className="h-6 w-6 text-red-600" />
                      )}
                      {(type === 'success') && (
                        <CheckCircle size={24} className="h-6 w-6 text-green-700" />
                      )}
                      {(type === 'info') && (
                        <Info size={24} className="h-6 w-6 text-blue-700" />
                      )}
                    </div>
                  )}

                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="text-sm text-gray-500">{content}</div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    'mt-5 flex flex-row gap-2 sm:mt-4 sm:flex sm:flex-row-reverse',
                  )}
                >
                  {buttons?.map(({ text, onClick, ...rest }, key) => (
                    <button
                      key={key}
                      {...(rest as any)}
                      onClick={(
                        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                      ) => handleButtonClick(e, onClick)}
                    >
                      {text || ''}
                    </button>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
