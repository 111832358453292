import React from 'react';
import DefaultModalComponent from './DefaultModalComponent';
import { pushModal, removeModal } from './modalNexus';

export const commonButtonStyle = `mt-3 inline-flex w-full justify-center rounded-md border bg-white px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm`;
export const basicButtonStyle = `border-gray-300 text-gray-700 hover:bg-gray-50 ${commonButtonStyle}`;
export const primaryButtonStyle = `bg-y-500 border-y-500 text-k-900 hover:bg-y-600 hover:border-y-600 ${commonButtonStyle}`;
export const errorButtonStyle = `bg-red-600 hover:bg-red-700 border-red-700 hover:border-red-700  ${commonButtonStyle} text-white`;

class ModalManager {
  defaultModalComponent = DefaultModalComponent;
  open = <T = any,>(
    modalProps: ModalProps<T>,
    component?: React.ComponentType<ModalProps>,
  ) => {
    return new Promise<T>((resolve) => {
      const key = generateUniqueKey();
      pushModal({
        key,
        component:
          component !== undefined ? component : this.defaultModalComponent,
        ...modalProps,
        close: (data: T) => {
          removeModal(key);
          resolve(data);
        },
      });
    });
  };

  alert = (
    message: string | React.ReactNode = '',
    {
      title,
      buttonText,
      yesButtonProps,
      type = 'error',
    }: {
      title?: string;
      buttonText?: string;
      yesButtonProps?: ModalButton;
      type?: ModalProps['type'];
    } = {},
  ) => {
    return this.open({
      title,
      type,
      content: message,
      buttons: [
        {
          className: basicButtonStyle,
          text: buttonText || '확인',
          ...yesButtonProps,
          onClick: (e, close) => {
            if (yesButtonProps?.onClick) {
              yesButtonProps?.onClick(e, close);
            }

            close();
          },
        },
      ],
    });
  };

  confirm = (
    message: string | React.ReactNode = '',
    type: 'confirm' | 'warning' | 'default' | 'delete' = 'default',
    {
      title,
      buttonText,
      yesText,
      noText,
      yesButtonProps,
      noButtonProps,
    }: {
      title?: string;
      buttonText?: string;
      yesText?: string;
      noText?: string;
      yesButtonProps?: ModalButton;
      noButtonProps?: ModalButton;
    } = {},
  ) => {
    let buttons: ModalButton[];
    switch (type) {
      case 'warning':
        buttons = [
          {
            className: primaryButtonStyle,
            text: yesText || '확인',
            ...yesButtonProps,
            onClick: (e, close) => {
              close(true);
            },
          },
          {
            className: basicButtonStyle,
            text: noText || '취소',
            ...noButtonProps,
            onClick: (e, close) => {
              close();
            },
          },
        ];
        break;
      case 'confirm':
        buttons = [
          {
            className: primaryButtonStyle,
            text: yesText || '확인',
            ...yesButtonProps,
            onClick: (e, close) => {
              close(true);
            },
          },
        ];
        break;
      case 'delete':
        buttons = [
          {
            text: yesText || '삭제',
            ...yesButtonProps,
            className: errorButtonStyle,
            onClick: (e, close) => {
              close(true);
            },
          },
          {
            className: basicButtonStyle,
            text: noText || '취소',
            ...noButtonProps,
            onClick: (e, close) => {
              close(false);
            },
          },
        ];
        break;
      default:
        buttons = [
          {
            className: primaryButtonStyle,
            text: yesText || '확인',
            ...yesButtonProps,
            onClick: (e, close) => {
              close(true);
            },
          },
          {
            className: basicButtonStyle,
            text: noText || '취소',
            ...noButtonProps,
            onClick: (e, close) => {
              close(false);
            },
          },
        ];
        break;
    }

    return this.open<boolean>({
      type,
      title,
      content: message,
      buttons: buttons,
    });
  };
}

function generateUniqueKey() {
  return `${Date.now()}-${Math.random()}`;
}

export interface ModalProps<T = any> {
  type?: 'confirm' | 'default' | 'delete' | 'warning' | 'error' | 'success' | 'info';
  className?: string;
  title?: string;
  content?: string | React.ReactNode;
  buttons?: ModalButton<T>[];
  close?: (data?: any) => void;
}

export interface ModalButton<T = any> {
  text?: string;
  className?: string;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    close: (data?: T) => void,
  ) => void;
}

export default new ModalManager();
